<template>
    <div>
      <!-- メニュー -->
      <DummyMenu class="unselectable blur backdrop-filter" style="pointer-events: none ;"/>
      <div class="main-panel">
        <!-- コンテンツ -->
        <RouterView />
        <div class="unselectable" style="pointer-events: none ;">
          <!-- ヘッダー -->
          <DummyHeader/>
        </div>
      </div>
    </div>
  </template>
  <script>
  import DummyMenu from '@/components/join/DummyMenu.vue';
  import DummyHeader from '@/components/join/DummyHeader.vue';
  
  export default {
    name: 'JoinBlurredIndex',
    components: {
      DummyMenu,
      DummyHeader,
    },
    data() {
      return {};
    },
    beforeCreate() {
      /* GA4 Googleアナリティクス 2021/10 */
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
  
      // 基本値のセット
      var GtagSetArray = {
        'user_id': 'U' + this.$store.getters['auth/id'],
        'user_properties': {
          'company_id': 'C' + this.$store.getters['auth/companyId'],
          'role': 'R' + this.$store.getters['auth/role']
        }
      };
  
      // 内部アクセス切り替え
      if (window.location.hostname == 'portal-stag.symphonict.com') {
        GtagSetArray.debug_mode = true;
        GtagSetArray.traffic_type = 'internal_stag';
      } else if (window.location.hostname == 'portal-dev.symphonict.com') {
        GtagSetArray.debug_mode = true;
        GtagSetArray.traffic_type = 'internal_dev';
      } else if (window.location.hostname == 'localhost') {
        GtagSetArray.debug_mode = true;
        GtagSetArray.traffic_type = 'internal_local';
      }
  
      // 送信
      gtag('config', 'G-Y9CV60BH7R', GtagSetArray);
      /* end GA4 Googleアナリティクス 2021/10 */
    },
    created() {
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    methods: {},
    computed: {},
  };
  </script>
  
  <style>
  /* メインコンテンツ */
  
  .main-content {
    min-height: calc(100vh - 60px);
    width: 100%;
    padding-top: 30px;
  }
  
  @media (min-width: 768px) {
  
    .content-panel {
      width: 100%;
      padding-top: 30px;
    }
  
    .js-set-sidebar-minimize {
      width: 60px;
    }
  }
  .unselectable {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
  }
  .backdrop-filter {
    z-index: 1100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    backdrop-filter: blur(5px);
  }
  .blur{
    filter: blur(5px);
  }
  </style>
