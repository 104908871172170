<!-- /join以下共通で使用するコンポーネント  -->
<template>
  <div>
    <div class="main-panel">
      <RouterView />
    </div>
  </div>
</template>

<script>
export default {
  name: 'JoinIndex',
};
</script>
