<!-- 画面表示は無いが削除するとエラーになるため空の<template>を置く -->
<template>
  <div></div>
</template>

<script>
import { joinApiMixin } from '@/mixins/join/join_api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';

export default {
  name: 'JoinStart',
  mixins: [joinApiMixin, tooltipMixin],
  created() {
    this.execute_api();
  },
  methods: {
    async execute_api() {
      this.isLoading = true;
      // API実行
      this.start = (await this.getApiNextUrl(`start/${this.$route.params.data_linkage_key}`)) || { result: false };
    },
  },
};
</script>
