// 「/join」以下用のAPIコール設定 他のmethodは適宜 importとexportに追加してください
// 動作確認はバックエンドが完成してから行ってください
import { OK, UNPROCESSABLE_ENTITY } from '@/util';

export const joinApiMixin = {
  methods: {
    // GETの共通処理 (取得して次の処理を行う場合)
    async getApiData(url) {
      this.setMultiCloudPortalCookie();
      try {
        this.$store.commit('view/pushLoading');
        const response = await this.axios.request({
          url: `/api/join/${url}`,
          crossDomain: true,
          xhrFields: { withCredentials: true },
          method: 'get',
        });

        const data = response.data;

        if (response.status === OK) {
          this.$store.commit('view/popLoading');
          return data;
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    // GETの共通処理 (バックエンドの処理後にリダイレクトする場合)
    async getApiNextUrl(url) {
      this.setMultiCloudPortalCookie();
      try {
        this.$store.commit('view/pushLoading');
        const response = await this.axios.request({
          url: `/api/join/${url}`,
          crossDomain: true,
          xhrFields: { withCredentials: true },
          method: 'get',
        });

        const data = response.data;

        if (response.status === OK) {
          this.redirect(data.next_url);
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    // POSTの共通処理 (バックエンドの処理後にリダイレクトする場合)
    async postApiNextUrl(url, params) {
      this.setMultiCloudPortalCookie();
      try {
        this.$store.commit('view/pushLoading');
        const response = await this.axios.request({
          url: `/api/join/${url}`,
          crossDomain: true,
          xhrFields: { withCredentials: true },
          method: 'post',
          data: params,
        });

        const data = response.data;

        if (response.status === OK) {
          this.$store.commit('view/popLoading');
          this.redirect(data.next_url);
        }
      } catch (error) {
        this.handleError(error);
      }
    },    
    // PUTの共通処理 (バックエンドの処理後にリダイレクトする場合)
    async putApiNextUrl(url, params) {
      this.setMultiCloudPortalCookie();
      try {
        this.$store.commit('view/pushLoading');
        const response = await this.axios.request({
          url: `/api/join/${url}`,
          crossDomain: true,
          xhrFields: { withCredentials: true },
          method: 'put',
          data: params,
        });

        const data = response.data;

        if (response.status === OK) {
          this.$store.commit('view/popLoading');
          this.redirect(data.next_url);
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    setMultiCloudPortalCookie: function () {
      const cookies = document.cookie;
      const cookiesArray = cookies.split(';');
      let sendCookies = [];
      const targetCookieId = ['XSRF-TOKEN', 'multicloudportal-key'];
      for (let c of cookiesArray) {
        const cArray = c.trim().split('=');
        if (targetCookieId.some((target) => target === cArray[0])) {
          // 取り出したいkeyと合致したら
          sendCookies.push(c);
        }
      }
    },
    handleError: function (error) {
      this.$store.commit('view/popLoading');
      if (error.response.status === UNPROCESSABLE_ENTITY) {
        // バリデーションエラー
        return { result: false, message: error.response.data.message, errors: error.response.data.errors };
      }
      // バリデーションエラー以外は一律エラーとする
      this.redirect(error.response.data.return_ng_url);
    },
    redirect: function (next_url) {
      this.$store.commit('view/popLoading');
      if (!next_url) {
        // vueの認証をクリア & エラーページへ
        this.$store.commit('auth/clearUser');
        this.$router.push({ path: '/500' });
      }
      window.location.href = next_url;
      return { result: false };
    },
  },
};
