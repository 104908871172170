<template>
  <div id="app">
    <!-- ローディングアニメーション -->
    <Loader />

    <!-- ページ -->
    <RouterView />
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';

export default {
  name: 'App',
  components: {
    Loader,
  },
  methods: {
    createTitleDesc: function(routeInstance) {
      // タイトルを設定
      if (routeInstance.meta.title) {
        var setTitle = routeInstance.meta.title + ' | サービスポータル';
        document.title = setTitle;
      } else {
        document.title = 'サービスポータル';
      }
    },
  },
  mounted: function() {
    var routeInstance = this.$route;
    this.createTitleDesc(routeInstance);
  },
  watch: {
    $route(routeInstance) {
      this.createTitleDesc(routeInstance);
    },
  },
};
</script>