<template>
  <main class="main-content content " v-if = !isLoading>
    <div id="register">
      <div class="p-20 card col-md-12 bgc-grey-200" style="z-index:1100;">
        <!-- タイトル -->
        <div>
          <h4 class="c-grey-900" data-e2e="page_title_name">企業選択</h4>
        </div>
        <!-- 白枠 -->
        <div class="card border-0">
          <div class="card-body">
            <!-- 登録企業表示 -->
            <div class="row form-inline form-group align-items-center mb-30px">
              <!-- ラベル -->
              <div class="col-12 col-lg-auto">
                <h3 class="decoration text-16px text-md-20px font-weight-bold mt-8px">
                  登録済企業名
                </h3>
              </div>
              <!-- 企業名表示フォーム -->
              <input class="col-lg h-75 align-items-center form-control" type="text" readonly :value="companyName">
              <!-- ボタン -->
              <div class="col-12 col-lg-auto ml-xl-auto align-items-center justify-content-center">
                <button
                  @click="registerCompany"
                  data-e2e="company_regist"
                  class="btn btn-info btn-icon-left w-100 w-lg-auto px-4 btn-sm add-btn-txt"
                  data-toggle="modal"
                  role="button"
                >
                  <div class="d-flex align-items-center">
                    <svg class="bi" fill="currentColor">
                      <use xlink:href="/img/bootstrap-icons.svg#person-plus"/>
                    </svg>
                    この企業で登録する
                  </div>
                </button>
              </div>
            </div>

            <!-- メッセージ -->
            <div class="mt-3 mb-30px">
              <span class="custom-margine">
                別の企業で登録したい場合は、Symphonictオーダーシステム会員登録画面より新規会員登録をしてください。
              </span>
              <br>
              <span class="custom-margine">
                Symphonictオーダーシステム会員登録画面は
                <a
                  :href=url
                  class="text-primary"
                  style="text-decoration: underline"
                >
                  こちら
                </a>
              </span>
              </div>
          </div>
        </div> 
      </div>
    </div>
  </main>
</template>
  
  <script>
  import { joinApiMixin } from '@/mixins/join/join_api.js';
  import { apiMixin } from '@/mixins/api.js';
  import { tooltipMixin } from '@/mixins/tooltip.js';

  export default {
    name: 'CompanySelect',
    mixins: [apiMixin,joinApiMixin, tooltipMixin],
    data() {
      return {
        companyName: '',
        url: '',
        value: ''
      };
    },
    beforeCreate() {},
    created() {
      this.getCompanyName();
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    methods: {
      async getCompanyName(){
        // API実行
        const company = (await this.getApiData(`company`));
        this.companyId = company.id;
        this.companyName  = company.company_kanji;
        this.url = company.url;
      },
      async registerCompany() {
        await this.putApiNextUrl(`company/${this.companyId}`)||[];
      }
    }
  };
  </script>
  
  <style>
    .custom-margine{
      margin-left: 23px;
    }
    .mt-8px { 
      margin-top: 8px;
    }
  </style>