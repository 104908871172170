<template>
  <main class="main-content content bgc-grey-200">
    <div class="p-20 col-md-12 bgc-grey-200" style="z-index:1100;">
      <div>
        <h4 class="c-grey-900 mt-3">企業・ユーザー登録</h4>
      </div>
      <div class="masonry-item">
        <div class="bgc-white p-20 bd card col-md-8">
          <form>
            <!-- 企業名(漢字) -->
            <div class="form-group">
              <label for="company_kanji">企業名(漢字)<span class="badge badge-danger ml-1 py-1">必須</span></label>
              <input
                type="name"
                :class="'form-control h-75' + (errors && errors.company_kanji && (errors.company_kanji.length > 0) ? ' is-invalid' : '')"
                :disabled="disabled_company_kanji"
                id="company_kanji"
                placeholder="企業名"
                v-model="register_data.company_kanji"
                ref="company_kanji"
                data-e2e="register_company_kanji"
              />
              <div class="text-danger" v-if="errors && errors.company_kanji">
                  <div v-for="(msg, index) in errors.company_kanji" :key="index">
                    {{ msg }}
                  </div>
              </div>
            </div>

            <!-- 企業名(カナ) -->
            <div class="form-group">
              <label for="company_kana">企業名(カナ)</label>
              <input
                type="name"
                :class="'form-control h-75' + (errors && errors.company_kana && (errors.company_kana.length > 0) ? ' is-invalid' : '')"
                :disabled="disabled_company_kana"
                id="company_kana"
                placeholder="キギョウメイ"
                v-model="register_data.company_kana"
                ref="company_kana"
                data-e2e="register_company_kana"
              />
              <div class="text-danger" v-if="errors && errors.company_kana">
                  <div v-for="(msg, index) in errors.company_kana" :key="index">
                    {{ msg }}
                  </div>
              </div>
            </div>

            <!-- 名前 -->
            <div class="form-group">
              <label for="name">名前<span class="badge badge-danger ml-1 py-1">必須</span></label>
              <input
                type="name"
                :class="'form-control h-75' + (errors && errors.name && (errors.name.length > 0) ? ' is-invalid' : '')"
                :disabled="disabled_name"
                id="name"
                placeholder="山田 太郎"
                v-model="register_data.name"
                ref="name"
                data-e2e="register_name"
              />
              <div class="text-danger" v-if="errors && errors.name">
                  <div v-for="(msg, index) in errors.name" :key="index">
                    {{ msg }}
                  </div>
              </div>
            </div>

            <!-- 部署名(漢字) -->
            <div class="form-group">
              <label for="department_kanji">部署名(漢字)</label>
              <input
                type="name"
                :class="'form-control h-75' + (errors && errors.department_kanji && (errors.department_kanji.length > 0) ? ' is-invalid' : '')"
                :disabled="disabled_department_kanji"
                id="department_kanji"
                placeholder="部署名"
                v-model="register_data.department_kanji"
                ref="department_kanji"
                data-e2e="register_department_kanji"
              />
              <div class="text-danger" v-if="errors && errors.department_kanji">
                  <div v-for="(msg, index) in errors.department_kanji" :key="index">
                    {{ msg }}
                  </div>
              </div>
            </div>
            
            <!-- 部署名(カナ) -->
            <div class="form-group">
              <label for="department_kana">部署名(カナ)</label>
              <input
                type="name"
                :class="'form-control h-75' + (errors && errors.department_kana && (errors.department_kana.length > 0) ? ' is-invalid' : '')"
                :disabled="disabled_department_kana"
                id="department_kana"
                placeholder="ブショメイ"
                v-model="register_data.department_kana"
                ref="department_kana"
                data-e2e="register_department_kana"
              />
              <div class="text-danger" v-if="errors && errors.department_kana">
                  <div v-for="(msg, index) in errors.department_kana" :key="index">
                    {{ msg }}
                  </div>
              </div>
            </div>
            
            <!-- メールアドレス -->
            <div class="form-group">
              <label for="email">メールアドレス<span class="badge badge-danger ml-1 py-1">必須</span></label>
              <input
                type="email"
                :class="'form-control h-75'"
                disabled
                id="email"
                placeholder="sample@example.com"
                v-model="register_data.email"
                data-e2e="register_email"
              />
            </div>

            <!-- 電話番号 -->
            <div class="form-group">
              <label for="phone_number">電話番号<span class="badge badge-danger ml-1 py-1">必須</span></label>
              <input
                type="name"
                :class="'form-control h-75' + (errors && errors.phone_number && (errors.phone_number.length > 0) ? ' is-invalid' : '')"
                :disabled="disabled_phone_number"
                id="phone_number"
                placeholder="000-0000-0000"
                v-model="register_data.phone_number"
                ref="phone_number"
                data-e2e="register_phone_number"
              />
              <div class="text-danger" v-if="errors && errors.phone_number">
                  <div v-for="(msg, index) in errors.phone_number" :key="index">
                    {{ msg }}
                  </div>
              </div>
            </div>
            
            <!-- パスワード -->
            <div class="form-group">
              <label for="password">パスワード<span class="badge badge-danger ml-1 py-1">必須</span></label>
              <input
                type="password"
                :class="'form-control h-75' + (errors && errors.password && (errors.password.length > 0) ? ' is-invalid' : '')"
                id="password"
                placeholder=""
                v-model="register_data.password"
                data-e2e="register_password"
              />
              <div class="text-danger" v-if="errors && errors.password">
                  <div v-for="(msg, index) in errors.password" :key="index">
                    {{ msg }}
                  </div>
              </div>
            </div>

            <!-- パスワード再入力 -->
            <div class="form-group">
              <label for="password_confirmation">パスワード再入力<span class="badge badge-danger ml-1 py-1">必須</span></label>
              <input
                type="password"
                :class="'form-control h-75' + (errors && errors.password_confirmation && (errors.password_confirmation.length > 0) ? ' is-invalid' : '')"
                id="password_confirmation"
                placeholder=""
                v-model="register_data.password_confirmation"
                data-e2e="register_password_confirmation"
              />
              <div class="text-danger" v-if="errors && errors.password_confirmation">
                  <div v-for="(msg, index) in errors.password_confirmation" :key="index">
                    {{ msg }}
                  </div>
              </div>
            </div>

            <!-- パスワードポリシー  -->
            <PasswordPolicy :passwordPolicyMin="this.passwordPolicyMin" :passwordPolicyMax="this.passwordPolicyMax" />

            <div class="d-flex justify-content-center">
              <button
                v-if="!isLoading"
                type="button"
                class="btn btn-info btn-sm"
                @click="submit()"
                data-e2e="register_submit"
              >
                登録する
              </button>
              <button v-show="isLoading" class="btn btn-info btn-sm" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                登録中…
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { joinApiMixin } from '@/mixins/join/join_api.js';
import { apiMixin } from '@/mixins/api.js';
import { tooltipMixin } from '@/mixins/tooltip.js';
import PasswordPolicy from '@/components/PasswordPolicy.vue';

export default {
  name: 'JoinRegister',
  mixins: [joinApiMixin, apiMixin, tooltipMixin],
  components: {
    PasswordPolicy,
  },
  data() {
    return {
      register_data: null,
      constants: [],
      errors: null,
      isLoading: false,
      passwordPolicyMin: null,
      passwordPolicyMax: null,
      passwordPolicyRegex: null,
    };
  },
  created() {
    this.initialization();
    this.getRegisterFormValues();
    this.getConstant();
  },
  methods: {
    initialization() {
      this.errors = {
        company_kanji: [],
        company_kana: [],
        name: [],
        department_kanji: [],
        department_kana: [],
        phone_number: [],
        password: [],
        password_confirmation: [],
      };
    },
    // 企業・ユーザー情報の初期値を取得
    async getRegisterFormValues() {
      this.register_data = (await this.getApiData(`register`)) || [];
      
      // APIの返却値にないパスワードに空白をセット
      this.register_data.password = '';
      this.register_data.password_confirmation = '';
      
      // 初期値がある場合にdisabledにする
      this.disabled_company_kanji = (this.register_data.company_kanji !== null) ? true : false;
      this.disabled_company_kana = (this.register_data.company_kana !== null) ? true : false;
      this.disabled_name = (this.register_data.name !== null) ? true : false;
      this.disabled_department_kanji = (this.register_data.department_kanji !== null) ? true : false;
      this.disabled_department_kana = (this.register_data.department_kana !== null) ? true : false;
      this.disabled_phone_number = (this.register_data.phone_number !== null) ? true : false;
      
      // 入力チェック時のエラー対策のため、nullの項目には改めて空文字をセットする
      this.register_data.company_kanji = (this.register_data.company_kanji === null) ? '' : this.register_data.company_kanji;
      this.register_data.company_kana = (this.register_data.company_kana === null) ? '' : this.register_data.company_kana;
      this.register_data.name = (this.register_data.name === null) ? '' : this.register_data.name;
      this.register_data.department_kanji = (this.register_data.department_kanji === null) ? '' : this.register_data.department_kanji;
      this.register_data.department_kana = (this.register_data.department_kana === null) ? '' : this.register_data.department_kana;
      this.register_data.phone_number = (this.register_data.phone_number === null) ? '' : this.register_data.phone_number;
    },
    
    // 定数情報を取得
    async getConstant() {
      this.constants = (await this.getApi('constant')) || [];

      for (const i in this.constants) {
        // パスワード最小文字数
        if (this.constants[i].key == 'passwordPolicyMin') {
          this.passwordPolicyMin = this.constants[i].value;
        }
        // パスワード最大文字数
        if (this.constants[i].key == 'passwordPolicyMax') {
          this.passwordPolicyMax = this.constants[i].value;
        }
        // パスワード正規表現
        if (this.constants[i].key == 'passwordPolicyRegex') {
          this.passwordPolicyRegex = this.constants[i].value;
        }
      }
    },

    // 登録
    async submit() {
      // バリデーション
      if (this.validater()) {
        await this.postUser();
      } else {
        this.$toasted.error('入力内容に誤りがあります');
      }
    },
    // 入力内容の検証
    validater() {
      this.errors = {
        company_kanji: [],
        company_kana: [],
        name: [],
        department_kanji: [],
        department_kana: [],
        phone_number: [],
        password: [],
        password_confirmation: [],
      };

      let result = true;

      // 企業名(漢字)
      if (this.register_data.company_kanji.length === 0) {
        result = false;
        this.errors.company_kanji.push('企業名(漢字)を入力してください。');
      } else if (this.register_data.company_kanji.length > 100) {
        result = false;
        this.errors.company_kanji.push('企業名(漢字)は100文字以内で入力してください。');
      }
      // 企業名(カナ)
      if (this.register_data.company_kana.length > 100) {
        result = false;
        this.errors.company_kana.push('企業名(カナ)は100文字以内で入力してください。');
      }
      // 名前
      if (this.register_data.name.length === 0) {
        result = false;
        this.errors.name.push('名前を入力してください。');
      } else if (this.register_data.name.length > 255) {
        result = false;
        this.errors.name.push('名前は255文字以内で入力してください。');
      }
      // 部署名(漢字)
      if (this.register_data.department_kanji.length > 100) {
        result = false;
        this.errors.department_kanji.push('部署名(漢字)は100文字以内で入力してください。');
      }
      // 部署名(カナ)
      if (this.register_data.department_kana.length > 100) {
        result = false;
        this.errors.department_kana.push('部署名(カナ)は100文字以内で入力してください。');
      }
      // 電話番号
      const phoneNumberWithHyphenPattern = /(^0[0-9]{1,4}-[0-9]{1,4}-[0-9]{4}$)/;
      const phoneNumberWithoutHyphenPattern = /(^0[0-9]{9,10}$)/;
      if (this.register_data.phone_number.length === 0) {
        result = false;
        this.errors.phone_number.push('電話番号を入力してください。');
      } else if (this.register_data.phone_number.length < 10 || this.register_data.phone_number.length > 13) {
        result = false;
        this.errors.phone_number.push('電話番号は10文字以上13文字以下で入力してください。');
      } else if (!this.register_data.phone_number.startsWith('0')) {
        result = false;
        this.errors.phone_number.push('電話番号は0から始めてください。');
      } else if (!phoneNumberWithHyphenPattern.test(this.register_data.phone_number) 
        && !phoneNumberWithoutHyphenPattern.test(this.register_data.phone_number.replace(/-/g, ''))) {
        result = false;
        this.errors.phone_number.push('電話番号の形式が正しくありません。');
      }

      // パスワード
      if (this.register_data.password.length === 0) {
        result = false;
        this.errors.password.push('パスワードを入力してください。');
      } else if (
        this.register_data.password.length < this.passwordPolicyMin ||
        this.passwordPolicyMax < this.register_data.password.length
      ) {
        result = false;
        this.errors.password.push(
          'パスワードの長さは' +
          this.passwordPolicyMin +
          '文字以上' +
          this.passwordPolicyMax +
          '文字以下で入力してください。'
        );
      } else if (!RegExp(this.passwordPolicyRegex).test(this.register_data.password)) {
        result = false;
        this.errors.password.push('パスワードの条件に則って入力してください。');
      }

      // パスワード再入力
      if (this.register_data.password_confirmation.length === 0) {
        result = false;
        this.errors.password_confirmation.push('パスワード再入力を入力してください。');
      } else if (this.register_data.password_confirmation !== this.register_data.password) {
        result = false;
        this.errors.password_confirmation.push('パスワードと同じ値を入力してください。');
      }

      return result;
    },
    
    // 企業・ユーザー情報を登録する
    async postUser() {
      this.isLoading = true;
      // POST用メソッドを実行
      const response = (await this.postApiNextUrl(`register`, this.register_data)) || { result: false };
      this.isLoading = false;

      if (response.message) {
        this.$toasted.error('入力内容に誤りがあります');

        if (response.errors) {
          this.errors = response.errors;
        }

        // 暫定対処 使用されているメアド入力時にエラー内容が表示されない問題
        if (response.message == '既に登録されているメールアドレスのため登録できません') {
          this.errors.email.push('既に登録されているメールアドレスのため登録できません。');
        }

      } else if (response.exclusive) {
        this.$toasted.error(response.exclusive);
      }
    },
  },
};
</script>

<style scoped>
.btn-width {
  width: 96px;
}

.alert-secondary {
  padding-left: 10px;
}

.alert-secondary p {
  padding-left: 1em;
  text-indent: -1em;
  margin: 0;
}

.asterisk {
  color: #ff6c9e;
  font-weight: bold;
  text-indent: 2em;
}
</style>
